'use client'

import { createContext } from 'react'
import { IPaginationContext } from './interface'

const defaultState: IPaginationContext = {
  href: '',
  currentPage: 0,
  paginationElements: [],
  hasNextPage: false,
  hasPrevPage: false,
}

export const PaginationContext = createContext<IPaginationContext>(defaultState)
