import { useMemo } from 'react'
import { getNumbersInRange } from '../../../../utils/number'
import { PaginationElement } from '../interface'

const usePaginationElements = (
  currentPage: number,
  totalPages: number,
  currentPageSiblingCount: number,
  edgePageCount: number,
): PaginationElement[] => {
  const startPages = useMemo(() => {
    const end = Math.min(edgePageCount - 1, totalPages - 1)
    return getNumbersInRange(0, end)
  }, [edgePageCount, totalPages])

  const endPages = useMemo(() => {
    const start = Math.max(0, totalPages - edgePageCount)
    return getNumbersInRange(start, totalPages - 1)
  }, [edgePageCount, totalPages])

  const middlePages = useMemo(() => {
    const start = Math.max(0, currentPage - currentPageSiblingCount)
    const end = Math.min(totalPages - 1, currentPage + currentPageSiblingCount)
    return getNumbersInRange(start, end)
  }, [currentPage, currentPageSiblingCount, totalPages])

  const unique = [...new Set([...startPages, ...middlePages, ...endPages])]
  return unique.reduce((acc, curr) => {
    const isActive = curr === currentPage
    const last = acc.slice(-1)[0]
    let newElements: PaginationElement[] = [{ index: curr, active: isActive }]

    const diff = curr - (typeof last?.index === 'number' ? last?.index : curr)
    if (diff === 2) {
      newElements = [{ index: curr - 1, active: curr - 1 === currentPage }, ...newElements]
    }
    if (diff > 2) {
      newElements = [{ active: false }, ...newElements]
    }

    return [...acc, ...newElements]
  }, [] as PaginationElement[])
}

export default usePaginationElements
