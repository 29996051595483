import { REGEX } from '../../../../utils/constants'
import { BasePagination, IPaginationContext } from '../interface'
import usePaginationElements from './use-pagination-elements'

interface UsePaginationProps extends BasePagination {
  href: string
}

const usePagination = ({
  href = '',
  currentPage,
  totalPages,
  currentPageSiblingCount,
  edgePageCount,
}: UsePaginationProps): IPaginationContext => {
  const paginationElements = usePaginationElements(currentPage, totalPages, currentPageSiblingCount, edgePageCount)

  return {
    href: href.replace(REGEX.trailingSlash, ''),
    paginationElements,
    hasNextPage: currentPage < totalPages - 1,
    hasPrevPage: currentPage > 0,
    currentPage,
  }
}

export default usePagination
