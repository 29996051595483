import { FC } from 'react'
import clsx from 'clsx'
import { PageButtonProps } from '@ignition/library/components/molecules/pagination'
import { BasePaginationButton } from './base-pagination-button'

export const PageButton: FC<PageButtonProps> = ({ children, active, className, href }) => {
  return (
    <BasePaginationButton active={active} className={clsx(className, 'px-4')} href={href}>
      {children}
    </BasePaginationButton>
  )
}
