'use client'

import { FC } from 'react'
import clsx from 'clsx'
import { Button } from '@/components/atoms/button'
import { PaginationOptions } from '@/types/site.interface'
import PaginationComp from '@ignition/library/components/molecules/pagination'
import { NextButton, PrevButton } from './nav-buttons'
import { PageButton } from './page-button'
import { Separator } from './separator'

export interface PaginationProps {
  baseHref: string
  className?: string
  currentPage: number
  pagination: PaginationOptions
  totalPages: number
}

const Pagination: FC<PaginationProps> = ({ baseHref, className, pagination, currentPage, totalPages }) => {
  if (pagination?.style === 'button') {
    const button = pagination?.button
    const slug = button?.linkType === 'entry' ? button?.entry?.slug : button?.href
    return (
      <div className="grid justify-center mt-10">
        <Button type="anchor" variant={button?.variant} href={slug}>
          {button?.title}
        </Button>
      </div>
    )
  }

  if (pagination?.style === 'pagination' && totalPages > 1) {
    return (
      <PaginationComp
        className={clsx(className, 'w-full')}
        currentPage={currentPage}
        currentPageSiblingCount={1}
        edgePageCount={1}
        totalPages={totalPages}
        href={baseHref}
      >
        <nav className="flex items-center justify-between border-t border-gray-200 sm:px-0">
          <PrevButton />
          <PaginationComp.List PageButton={PageButton} Separator={Separator} className="hidden md:-mt-px sm:flex" />
          <NextButton />
        </nav>
      </PaginationComp>
    )
  }

  return null
}

export default Pagination
