import { FC } from 'react'
import { CheckCircle, XCircle } from 'phosphor-react-sc'
import { AlertStatus } from './interface'

interface AlertIconProps {
  status: AlertStatus
}

export const AlertIcon: FC<AlertIconProps> = ({ status }) => {
  const render = () => {
    switch (status) {
      case 'success':
        return <CheckCircle className="h-5 w-5 text-green-400" aria-hidden="true" weight="fill" />
      case 'error':
        return <XCircle className="h-5 w-5 text-red-400" aria-hidden="true" weight="fill" />
      default:
        return null
    }
  }

  return render()
}
